#dwv {
  height: 100%;
}

.button-row {
  text-align: center;
}

/* Layers */
.layerGroup {
  position: relative;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 600px;
}

.layer {
  position: absolute;
  pointer-events: none;
}

.drawDiv {
  position: absolute;
  pointer-events: none;
}

/* drag&drop */
.dropBox {
  margin: auto 8%;
  text-align: center;
  vertical-align: middle;
  width: 100%;
  height: 90%;
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.dropBoxBorder {
  border: 5px dashed #e8eaf6; /*indigo:light*/
}

.dropBoxBorder.hover {
  border: 5px dashed #3f51b5; /*indigo*/
}

#input-file-link {
  text-decoration: underline;
}
