.ant-modal .ant-modal-content {
    margin: 0 auto;
    background: #ffffff;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 15px;
    padding: 0;
    width: 350px;
    min-width: 300px;
}

.ant-modal .ant-modal-close, .ant-modal .ant-modal-header, .ant-modal .ant-modal-footer {
    width: 100%;
    display: none;
    padding: 0;
}

.ant-modal .ant-modal-body {
    padding: 0;
    width: 350px;
}

strong {
    color:#E01B4B;
}

input[type="checkbox"] {
    cursor: pointer;
    accent-color: #E01B4B;
}