#lists-charts, #charts, .doubleCharts, .quadrupleCharts {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 40px;
    width: 100%;
}

#lists-charts .section {
    width: 48%;
}

#charts .section, #charts .main-section {
    width: 100%;
}

.doubleCharts>* {
    width: 48%;
}

.quadrupleCharts>* {
    width: 22%;
}

#lists-charts .section,
#charts .section {
    box-shadow: 0px 5px 20px #0000000D;
    padding: 50px;
    border-radius: 10px;
}

#lists-charts .section .header-section h2,
#charts .section .header-section h2 {
    flex: 2;
    color: #E01B4B;
    display: inline-block;
    margin-bottom: 0;
    width: 100%;
    font-weight: 800;
}
#lists-charts .section .header-section select,
#charts .section .header-section select {
    flex: 1;
    width: max-content;
    max-width: 300px;
    border-radius: 11px;
    border-color: #F5F5F5;
    background-color: #F5F5F5;
    background-image: linear-gradient(45deg, transparent 50%, black 50%), linear-gradient(135deg, black 50%, transparent 50%), linear-gradient(to right, #F5F5F5, #F5F5F5);
    background-position: calc(100% - 20px) calc(1em + 2px), calc(100% - 15px) calc(1em + 2px), 100% 0;
    background-size: 5px 5px, 5px 5px, 2.5em 2.5em;
    background-repeat: no-repeat;
    height: 50px;
}

#lists-charts .section .main-section table thead tr th,
#charts .section .main-section table thead tr th {
    background-color: transparent;
    padding: 0;
    border-bottom: none;
}
#lists-charts .section .main-section table tbody tr:hover td,
#charts .section .main-section table tbody tr:hover td {
    text-decoration: underline;
    background-color: transparent !important;
}
#lists-charts .section .main-section table tbody tr td,
#charts .section .main-section table tbody tr td {
    padding-left: 0;
    padding-right: 0;
    text-align: start;
}

#charts .section .main-section .quadrupleCharts p {
    text-transform: uppercase;
    text-align: center;
    font-weight: 600;
    font-size: .8rem;
    margin-bottom: 0;
}

@media screen and (max-width: 1280px) {
    #lists-charts .section {
        width: 100%;
    }
}