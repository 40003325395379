#footer {
    display: flex;
    justify-content: space-between;
    background-color: #f5f5f5;
    padding: 42px 2rem 132px;
}
#footer .image {
    width: 109px;
}
#footer .image img {
    width: 100%;
}

#footer .links {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
}
#footer .links ul {
    padding: 0;
}
#footer .links li {
    list-style: none;
}
#footer .links li a {
    color: #2F3C4C;
    font-weight: 500;
}
#footer .links .social-links {
    margin: 0 0 0 auto;
}
#footer .links .social-links li {
    display: inline-block;
}
#footer .links .social-links li+li {
    margin-left: 15px;
}
#footer .links .social-links li a {
    font-size: 1.5rem;
}
/* 
#footer .links .pages {}
#footer .links .pages-profile {}
#footer .links .pages-terms {margin-right: 70px;}
#footer .links .social-links {} */

.svg-inline--fa {
    display: inline-block;
    height: 1em;
    overflow: visible;
    vertical-align: -.125em;
}

@media screen and (max-width: 1240px) {
    #footer .links {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        column-gap: 40px;
    }
    #footer .links .social-links {
        margin: 0;
    }    
}

@media screen and (max-width: 1010px) {
    #footer {
        display: flex;
        gap: 40px;
        align-items: center;
        flex-direction: column;
        background-color: #f5f5f5;
        padding: 42px 2rem 132px;
    }
}