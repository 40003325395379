span.error {
    font-size: .8rem;
    color: #f00;
    margin-bottom: 10px;
}

.ant-modal .ant-modal-content {
    margin: 0 auto;
    background: #ffffff;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 15px;
    padding: 0;
    width: -moz-fit-content;
    width: fit-content;
    min-width: 300px;
}

.ant-modal .ant-modal-close, .ant-modal .ant-modal-header, .ant-modal .ant-modal-footer {
    width: 100%;
    display: none;
    padding: 0;
}

.ant-modal .ant-modal-body {
    padding: 0;
}

.ant-select+.ant-select {
    margin-top: 10px;
}
.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    padding: 27px;
    border: none;
    background-color: #f5f5f5;
    border-radius: 11px;
}

.ant-select-single .ant-select-selector .ant-select-selection-search {
    right: 0;
    left: 0;
}

.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border: none;
    box-shadow: none;
    background-color: #f5f5f5;
    border-radius: 11px;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
    width: 100%;
    border-radius: 11px;
    padding: 27px;
    background-color: #f5f5f5;
    border: 1px solid #0000 !important;
} 
.ant-select.error:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
    border: 1px solid #ff0000 !important;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input:focus {
    outline: 2px solid #000;
}

.ant-select-dropdown {
    border-radius: 11px;
    box-shadow: 0px 3px 6px #00000029;
}

.ant-select-item {
    border-radius: 11px;
    padding: 10px 15px;
}

strong {
    color:#E01B4B;
}

input[type="checkbox"] {
    cursor: pointer;
    accent-color: #E01B4B;
}